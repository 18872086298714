// @ts-nocheck
/**
 * squadbox.ai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentView } from './documentView';


export interface AssistantView { 
    /**
     * The chat assistant uuid
     */
    uuid: string;
    /**
     * The chat assistant owner
     */
    ownedBy?: string;
    /**
     * The chat assistant creator
     */
    createdBy: AssistantView.CreatedByEnum;
    /**
     * The chat assistant name
     */
    name: string;
    /**
     * The slug of the assistant, a unique human-readable identifier
     */
    slug: string | null;
    /**
     * The chat assistant logo
     */
    logo: string;
    /**
     * The chat assistant description
     */
    description: string;
    /**
     * The chat assistant prompt
     */
    prompt: string;
    /**
     * The chat assistant visibility
     */
    visibility: AssistantView.VisibilityEnum;
    /**
     * The chat assistant category
     */
    category: AssistantView.CategoryEnum;
    /**
     * The chat assistant uuid of the assistant that this assistant was duplicated from
     */
    duplicatedFrom: string | null;
    /**
     * Created at timestamp
     */
    createdAt: string;
    /**
     * Updated at timestamp
     */
    updatedAt: string;
    /**
     * Archived at timestamp
     */
    archivedAt: string | null;
    /**
     * Whether or not the assistant is active
     */
    active: boolean;
    /**
     * The status of the assistant (based off the documents status)
     */
    status: AssistantView.StatusEnum;
    /**
     * The list of documents associated to the assistant
     */
    documents: Array<DocumentView>;
}
export namespace AssistantView {
    export type CreatedByEnum = 'user' | 'hs';
    export const CreatedByEnum = {
        user: 'user' as CreatedByEnum,
        hs: 'hs' as CreatedByEnum
    };
    export type VisibilityEnum = 'public' | 'private' | 'shared';
    export const VisibilityEnum = {
        public: 'public' as VisibilityEnum,
        private: 'private' as VisibilityEnum,
        shared: 'shared' as VisibilityEnum
    };
    export type CategoryEnum = 'specialized' | 'essential' | 'custom';
    export const CategoryEnum = {
        specialized: 'specialized' as CategoryEnum,
        essential: 'essential' as CategoryEnum,
        custom: 'custom' as CategoryEnum
    };
    export type StatusEnum = 'processing' | 'failed' | 'ready';
    export const StatusEnum = {
        processing: 'processing' as StatusEnum,
        failed: 'failed' as StatusEnum,
        ready: 'ready' as StatusEnum
    };
}



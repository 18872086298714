import * as fromSeriousSystem from '@serious-system';

/**
 * List of standard assistant icons
 */
export const ASSISTANT_ICONS = [
  'email-filled',
  'convention-filled',
  'summary-filled',
  'note-filled',
  'voice-filled',
  'translate-filled',
  'text-filled',
  'daily-recap-filled',
  'presentation-filled',
  'prompt-filled',
  'squadbox-guide-filled',
  'idea-filled',
  'feedback-filled',
];

/**
 * List of custom assistant icons
 */
export const ASSISTANT_CUSTOM_ICONS = [
  'default',
  'question',
  'comment',
  'info',
  'document',
  'file-check',
  'file-edit',
  'list',
  'bill',
  'folder',
  'briefcase',
  'search',
  'user-time',
  'user-check',
  'handshake',
  'bulb',
  'clock',
  'calendar-edit',
  'shield',
  'law',
  'monitor',
  'trend',
  'certificate',
  'target',
];

/**
 * Determines the icon type based on the provided path
 * @param logoPath The icon path to check
 * @returns 'assistant', 'custom' or 'image'
 */
export function getLogoType(
  logoPath: string
): 'assistant' | 'custom' | 'image' {
  if (ASSISTANT_ICONS.includes(logoPath)) {
    return 'assistant';
  }

  if (ASSISTANT_CUSTOM_ICONS.includes(logoPath)) {
    return 'custom';
  }

  return 'image';
}

/**
 * Converts an icon path to AssistantIconsType
 * @param logoPath The icon path to convert
 * @returns The corresponding AssistantIconsType
 */
export function getAssistantIcon(
  logoPath: string
): fromSeriousSystem.AssistantIconsType {
  return logoPath as unknown as fromSeriousSystem.AssistantIconsType;
}

/**
 * Converts an icon path to AssistantCustomIconsType
 * @param logoPath The icon path to convert
 * @returns The corresponding AssistantCustomIconsType
 */
export function getAssistantCustomIcon(
  logoPath: string
): fromSeriousSystem.AssistantCustomIconsType {
  return logoPath as unknown as fromSeriousSystem.AssistantCustomIconsType;
}

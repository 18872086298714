import { createActionGroup, emptyProps, props } from '@ngrx/store';
import * as fromGenerated from '../../../_generated';
import { AssistantViewWithCategoryLabel } from './assistants.reducer';

export const AssistantsActions = createActionGroup({
  source: 'Assistants',
  events: {
    'Load Assistants': emptyProps(),
    'Load Assistants Success': props<{
      assistants: AssistantViewWithCategoryLabel[];
    }>(),
    'Load Assistants Failure': props<{ error: string }>(),
  },
});

export const AssistantActions = createActionGroup({
  source: 'Assistant',
  events: {
    'Select Assistant': props<{ uuid: string }>(),
    'Unselect Assistant': emptyProps(),
    'Add Assistant': props<{ uuid: string }>(),
    'Add Assistant Success': props<{ uuid: string }>(),
    'Add Assistant Failure': props<{ error: string }>(),
    'Remove Assistant': props<{ uuid: string }>(),
    'Remove Assistant Success': props<{ uuid: string }>(),
    'Remove Assistant Failure': props<{ error: string }>(),
    'Received Assistant Status Updated From Socket': props<{
      assistant: AssistantViewWithCategoryLabel;
    }>(),
    'Duplicate Assistant': props<{
      uuid: string;
      name: string;
      description: string;
    }>(),
    'Duplicate Assistant Success': props<{
      assistant: AssistantViewWithCategoryLabel;
    }>(),
    'Duplicate Assistant Failure': props<{ error: string }>(),
    'Delete Assistant': props<{
      uuid: string;
    }>(),
    'Delete Assistant Success': props<{
      uuid: string;
    }>(),
    'Delete Assistant Failure': props<{
      error: string;
    }>(),
  },
});

export const AssistantModalActions = createActionGroup({
  source: 'AssistantModal',
  events: {
    'Open Save Assistant Modal': emptyProps(),
    'Close Save Assistant Modal': emptyProps(),
    'Save Assistant': props<{ assistant: fromGenerated.CreateAssistantDto }>(),
    'Save Assistant Success': props<{
      assistant: AssistantViewWithCategoryLabel;
    }>(),
    'Save Assistant Failure': props<{ error: string }>(),
    'Edit Assistant': props<{
      uuid: string;
      assistant: fromGenerated.UpdateAssistantDto;
    }>(),
    'Edit Assistant Success': props<{
      assistant: AssistantViewWithCategoryLabel;
    }>(),
    'Edit Assistant Failure': props<{ error: string }>(),
    'Unset Error': emptyProps(),
  },
});

import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import * as fromGenerated from '../../../_generated';
import { AssistantViewWithCategoryLabel } from './assistants.reducer';

type AssistantTranslation = Pick<
  AssistantViewWithCategoryLabel,
  'name' | 'description' | 'categoryLabel'
>;

class AssistantTranslationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AssistantTranslationError';
  }
}

/**
 * Get the assistant name/description/category-label with the correct translation. If the category
 * is custom, it will return the assistant as is (no translation).
 */
export const getAssistantTranslation = (
  assistant: Pick<
    fromGenerated.AssistantView,
    'name' | 'description' | 'category'
  >,
  translateService: TranslateService
): AssistantTranslation => {
  const translations: AssistantTranslation = {
    ...assistant,
    categoryLabel: null,
  };

  if (assistant.category === 'custom') {
    return translations;
  }

  const nameTranslationKey = `ASSISTANTS.CATEGORIES.${assistant.category.toUpperCase()}.${assistant.name.toUpperCase()}`;
  const nameTranslation: string | undefined = translateService.instant(
    nameTranslationKey
  ) as string;
  if (nameTranslationKey === nameTranslation) {
    Sentry.captureException(
      new AssistantTranslationError(
        `Translation for ${nameTranslationKey} not found`
      )
    );
  } else {
    translations.name = nameTranslation;
  }

  const descriptionTranslationKey = `ASSISTANTS.CATEGORIES.${assistant.category.toUpperCase()}.${assistant.description.toUpperCase()}`;
  const descriptionTranslation: string | undefined = translateService.instant(
    descriptionTranslationKey
  ) as string;
  if (descriptionTranslationKey === descriptionTranslation) {
    Sentry.captureException(
      new AssistantTranslationError(
        `Translation for ${descriptionTranslationKey} not found`
      )
    );
  } else {
    translations.description = descriptionTranslation;
  }

  const categoryTranslationKey = `ASSISTANTS.CATEGORIES.${assistant.category.toUpperCase()}.LABEL`;
  const categoryTranslation: string | undefined = translateService.instant(
    categoryTranslationKey
  ) as string;
  if (categoryTranslationKey === categoryTranslation) {
    Sentry.captureException(
      new AssistantTranslationError(
        `Translation for ${categoryTranslationKey} not found`
      )
    );
  } else {
    translations.categoryLabel = categoryTranslation;
  }

  return translations;
};

/**
 * Get the sort priority of an assistant category.
 * - Specialized: 0
 * - Essential: 1
 * - Custom: 2
 */
const getAssistantCategorySortPriority = (
  category: fromGenerated.AssistantView['category']
): number => {
  switch (category) {
    case 'specialized':
      return 0;
    case 'essential':
      return 1;
    case 'custom':
    default:
      return 2;
  }
};

/**
 * Sort assistants by category and name.
 * - First sort by category priority
 * - If same category, then sort by name
 */
export const sortAssistantsComparer = (
  assistantA: Pick<fromGenerated.AssistantView, 'category' | 'name'>,
  assistantB: Pick<fromGenerated.AssistantView, 'category' | 'name'>
): number => {
  const aPriority = getAssistantCategorySortPriority(assistantA.category);
  const bPriority = getAssistantCategorySortPriority(assistantB.category);

  // First sort by priority (tag order)
  if (aPriority !== bPriority) {
    return aPriority - bPriority;
  }

  // Within the same tag category, sort by name
  return assistantA.name.localeCompare(assistantB.name, undefined, {
    caseFirst: 'upper',
  });
};

/**
 * Filters assistants based on feature flags.
 * - Custom assistants are always included
 * - Essential and Specialized assistants are included only if their feature flag is enabled
 */
export const filterAssistantByFeatureFlag = (
  assistant: Pick<fromGenerated.AssistantView, 'category' | 'slug'>,
  featureFlags: Record<string, boolean>
): boolean => {
  if (assistant.category !== 'custom' && assistant.slug) {
    if (!(assistant.slug in featureFlags)) {
      Sentry.captureEvent({
        message: `Feature flag ${assistant.slug} not found`,
        level: 'error',
      });
      return false;
    }
    return featureFlags[assistant.slug];
  }
  return true;
};

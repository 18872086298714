import { NgClass } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import { debounceTime, map, mergeMap, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../../environments/environment';
import * as fromGenerated from '../../../_generated';
import * as fromFeatureFlags from '../../../feature-flags';
import * as fromLayout from '../../../layout';
import { AssistantModalActions } from '../store/assistants.actions';
import {
  AssistantsState,
  assistantsFeature,
} from '../store/assistants.reducer';
import { FileActions } from '../store/files.actions';
import {
  FileUploadState,
  FilesState,
  filesFeature,
} from '../store/files.reducer';
import * as fromAssistantIcon from './assistant-icon.helper';
import { AssistantLogoDropdownComponent } from './assistant-logo-dropdown.component';
import {
  FileUploadAction,
  FileUploadComponent,
  FileWithId,
} from './file-upload.component';

@Component({
  selector: 'squadbox-assistant-save-modal',
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    fromSeriousSystem.ModalComponent,
    fromSeriousSystem.InputComponent,
    fromSeriousSystem.TextareaComponent,
    fromSeriousSystem.ButtonDirective,
    fromSeriousSystem.AvatarDirective,
    fromSeriousSystem.UseUIBasicIconDirective,
    fromSeriousSystem.UseAssistantIconDirective,
    fromSeriousSystem.UseAssistantCustomIconDirective,
    fromFeatureFlags.FeatureFlagsDirective,
    FileUploadComponent,
    AssistantLogoDropdownComponent,
    NgClass,
  ],
  template: `
    <sd-modal
      [isModalVisible]="isSaveAssistantModalOpened()"
      [modalOptions]="{
        title: 'ASSISTANTS.SAVE_MODAL.TITLE' | translate,
        showCloseButton: !isMobile(),
        isFullscreen: isMobile(),
        actions: [
          {
            label: 'ASSISTANTS.SAVE_MODAL.CTA_CANCEL' | translate,
            action: 'cancel',
            type: 'cancel',
            disabled: false,
          },
          {
            label: 'ASSISTANTS.SAVE_MODAL.CTA_SAVE' | translate,
            action: 'save',
            type: 'save',
            disabled: isSaveAssistantFormDisabled(),
          },
        ],
      }"
      (triggerAction)="handleDesktopModalTriggerActionCTA($event)"
    >
      <form
        class="flex flex-col gap-4 w-full tablet-portrait:min-w-150"
        [formGroup]="saveAssistantForm"
        [class.h-full]="isMobile()"
      >
        <div class="flex flex-row justify-between gap-2">
          <div class="flex-grow">
            <sd-input
              class="flex-grow"
              [class.max-w-44]="isMobile()"
              formControlName="name"
              [label]="'ASSISTANTS.SAVE_MODAL.FORM.NAME.LABEL' | translate"
              [placeholder]="
                'ASSISTANTS.SAVE_MODAL.FORM.NAME.PLACEHOLDER' | translate
              "
              [limit]="NAME_MAX_LENGTH"
            ></sd-input>
          </div>
          <div class="flex flex-col gap-2 w-24 relative">
            <p
              class="typo-caption font-semibold leading-4 h-5 text-center px-1 py-0.5"
              [ngClass]="{
                'text-neutral-400 pointer-events-none': isReadOnly()
              }"
            >
              {{ 'ASSISTANTS.SAVE_MODAL.FORM.IMAGE.LABEL' | translate }}
            </p>
            @if (saveAssistantForm.get('logo')?.value) {
            <button
              class="
              flex items-center justify-center
              size-12 rounded-full mx-auto
              border border-neutral-200
              text-primary-500/50
              bg-shades-white hover:bg-primary-500/5
              "
              [class.pointer-events-none]="isReadOnly()"
              (click)="toggleLogoDropdown()"
            >
              @let logo = saveAssistantForm.get('logo')?.value || ''; @switch
              (fromAssistantIcon.getLogoType(logo)) { @case ('assistant') {
              <svg
                [sdUseAssistantIcon]="fromAssistantIcon.getAssistantIcon(logo)"
                class="size-6 opacity-40"
              ></svg>
              } @case ('custom') {
              <svg
                class="size-6"
                [sdUseAssistantCustomIcon]="
                  fromAssistantIcon.getAssistantCustomIcon(logo)
                "
              ></svg>
              } @default {
              <img
                class="size-6"
                [src]="saveAssistantForm.get('logo')?.value"
              />
              }}
            </button>
            @if (isLogoDropdownVisible()) {
            <squadbox-assistant-logo-dropdown
              [logos]="fromAssistantIcon.ASSISTANT_CUSTOM_ICONS"
              (logoClicked)="handleLogoClick($event)"
              (clickedOutside)="handleLogoDropdownOutsideClick($event)"
            ></squadbox-assistant-logo-dropdown>
            } }
          </div>
        </div>
        <sd-textarea
          class="[&>div>textarea]:h-20"
          formControlName="description"
          [label]="'ASSISTANTS.SAVE_MODAL.FORM.DESCRIPTION.LABEL' | translate"
          [placeholder]="
            'ASSISTANTS.SAVE_MODAL.FORM.DESCRIPTION.PLACEHOLDER' | translate
          "
          [limit]="isReadOnly() ? 0 : DESCRIPTION_MAX_LENGTH"
        ></sd-textarea>

        <squadbox-file-upload
          label="Select Source"
          placeholder="Select a source"
          formControlName="files"
          [filesLoaders]="filesLoaders()"
          [squadboxFeatureFlag]="'ff-create-ai-assistant-with-document-s'"
          (valueChanged)="handleFileInputChange($event)"
        ></squadbox-file-upload>

        <sd-textarea
          class="
            [&>div]:h-full [&>div>textarea]:flex-grow
            [&>div>textarea]:h-60 tablet-portrait:[&>div>textarea]:h-70
          "
          [class.flex-grow]="isMobile()"
          formControlName="prompt"
          [label]="'ASSISTANTS.SAVE_MODAL.FORM.PROMPT.LABEL' | translate"
          [placeholder]="
            'ASSISTANTS.SAVE_MODAL.FORM.PROMPT.PLACEHOLDER' | translate
          "
          [limit]="isReadOnly() ? 0 : assistantPromptMaxLength"
        ></sd-textarea>
      </form>
    </sd-modal>

    <sd-modal
      [isModalVisible]="isConfirmCancelModalOpened()"
      [modalOptions]="{
        title: 'ASSISTANTS.SAVE_MODAL_CONFIRM_CANCEL.TITLE' | translate,
        showCloseButton: false,
        actions: [
          {
            label: 'ASSISTANTS.SAVE_MODAL_CONFIRM_CANCEL.CTA_CANCEL' | translate,
            action: 'cancel',
            type: 'cancel',
            disabled: false,
          },
          {
            label: 'ASSISTANTS.SAVE_MODAL_CONFIRM_CANCEL.CTA_DISCARD' | translate,
            action: 'delete',
            type: 'delete',
            disabled: false,
          },
        ],
        backdropDismiss: true
      }"
      (triggerAction)="handleDesktopModalConfirmCancelCTA($event)"
    >
      <div class="w-auto tablet-portrait:w-120">
        {{ 'ASSISTANTS.SAVE_MODAL_CONFIRM_CANCEL.DESCRIPTION' | translate }}
      </div>
    </sd-modal>
  `,
  styles: [``],
})
export class AssistantModalComponent {
  private readonly translateService = inject(TranslateService);
  private readonly layoutStore = inject(Store<fromLayout.LayoutState>);
  private readonly assistantsStore = inject(Store<AssistantsState>);
  private readonly filesStore = inject(Store<FilesState>);
  private readonly actions$ = inject(Actions);
  private readonly fileUploadTransactionId = signal<string>('');

  protected readonly NAME_MIN_LENGTH = 2;
  protected readonly NAME_MAX_LENGTH = 40;
  protected readonly DESCRIPTION_MIN_LENGTH = 2;
  protected readonly DESCRIPTION_MAX_LENGTH = 350;

  protected readonly fromAssistantIcon = fromAssistantIcon;

  public readonly assistantPromptMaxLength =
    environment.assistantPromptMaxLength;
  public readonly selectedAssistant = this.assistantsStore.selectSignal(
    assistantsFeature.selectSelectedAssistant
  );
  public readonly isMobile = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsMobile
  );
  public readonly filesLoaders = this.filesStore.selectSignal(
    filesFeature.selectFilesLoaders
  );
  public readonly saveAssistantForm = new FormGroup({
    name: new FormControl('', {
      nonNullable: true,
      validators: [
        (control) => Validators.required(control),
        Validators.minLength(this.NAME_MIN_LENGTH),
        Validators.maxLength(this.NAME_MAX_LENGTH),
      ],
    }),
    logo: new FormControl(fromAssistantIcon.ASSISTANT_CUSTOM_ICONS[0], {
      nonNullable: true,
      validators: [(control) => Validators.required(control)],
    }),
    description: new FormControl('', {
      nonNullable: true,
      validators: [
        (control) => Validators.required(control),
        Validators.minLength(this.DESCRIPTION_MIN_LENGTH),
        Validators.maxLength(this.DESCRIPTION_MAX_LENGTH),
      ],
    }),
    files: new FormControl<FileUploadState[]>(
      {
        value: [],
        disabled: false,
      },
      { nonNullable: true }
    ),
    prompt: new FormControl('', {
      nonNullable: true,
      validators: [
        (control) => Validators.required(control),
        Validators.minLength(2),
        Validators.maxLength(this.assistantPromptMaxLength),
      ],
    }),
    visibility: new FormControl(
      {
        value: fromGenerated.CreateAssistantDto.VisibilityEnum.private,
        disabled: true,
      },
      {
        nonNullable: true,
        validators: [
          (control) => Validators.required(control),
          Validators.pattern(
            `^(${fromGenerated.CreateAssistantDto.VisibilityEnum.private}|${fromGenerated.CreateAssistantDto.VisibilityEnum.public})$`
          ),
        ],
      }
    ),
  });
  public readonly saveAssistantFormValidity = toSignal(
    this.saveAssistantForm.valueChanges.pipe(
      debounceTime(300),
      map(() => this.saveAssistantForm.valid)
    ),
    {}
  );
  public readonly isSaveAssistantModalOpened =
    this.assistantsStore.selectSignal(
      assistantsFeature.selectIsSaveAssistantModalOpened
    );
  public readonly isConfirmCancelModalOpened = signal(false);
  public readonly isLogoDropdownVisible = signal(false);
  public readonly isReadOnly = computed(() => {
    const selectedAssistant = this.selectedAssistant();
    return selectedAssistant?.category === 'essential';
  });

  constructor() {
    const resetFormOnOpen$ = this.actions$.pipe(
      ofType(AssistantModalActions.openSaveAssistantModal),
      takeUntilDestroyed(),
      tap(() => {
        this.saveAssistantForm.reset();
        this.saveAssistantForm.enable();
        this.assistantsStore.dispatch(AssistantModalActions.unsetError());
        this.fileUploadTransactionId.set(uuidv4());
      })
    );
    const updateFormWithSelectedAssistant$ = this.assistantsStore
      .select(assistantsFeature.selectSelectedAssistant)
      .pipe(
        takeUntilDestroyed(),
        tap((selectedAssistant) => {
          if (selectedAssistant) {
            /**
             * 1. Upsert and patch form with assistant documents/files
             */
            const files =
              (selectedAssistant.documents
                ?.filter((doc) => !doc.deletedAt) // Filter out soft deleted documents
                .map((doc) => ({
                  id: doc.uuid,
                  name: doc.filename,
                  extension: doc.extension,
                  size: doc.size,
                })) as FileUploadState[]) ?? [];

            this.filesStore.dispatch(
              FileActions.upsertFiles({
                files,
              })
            );

            this.saveAssistantForm.patchValue({
              ...selectedAssistant,
              files,
            });

            /**
             * 2. Disable form fields if assistant is essential
             */
            if (selectedAssistant.category === 'essential') {
              this.saveAssistantForm.disable();
            }
          }
        })
      );

    resetFormOnOpen$
      .pipe(mergeMap(() => updateFormWithSelectedAssistant$))
      .subscribe();
  }

  public isSaveAssistantFormDisabled = computed(() => {
    const isFileLoading = this.filesStore.selectSignal(
      filesFeature.selectAreFilesLoading
    )();

    if (isFileLoading) {
      return true;
    }

    return !this.saveAssistantFormValidity();
  });

  public handleDesktopModalTriggerActionCTA(action: string): void {
    switch (action as fromSeriousSystem.ModalAction['type']) {
      case 'save':
        return this.saveAssistantForm.valid
          ? this.handleSaveAssistant()
          : this.handleFormError();
      case 'cancel':
        if (this.isSaveAssistantFormDirty()) {
          this.isConfirmCancelModalOpened.set(true);
        } else {
          this.assistantsStore.dispatch(
            AssistantModalActions.closeSaveAssistantModal()
          );
          this.saveAssistantForm.reset();
        }
        break;
    }
  }

  public handleDesktopModalConfirmCancelCTA(action: string): void {
    switch (action as fromSeriousSystem.ModalAction['type']) {
      case 'delete':
        this.assistantsStore.dispatch(
          AssistantModalActions.closeSaveAssistantModal()
        );
        this.saveAssistantForm.reset();
        break;
      case 'cancel':
        this.isConfirmCancelModalOpened.set(false);
        break;
    }

    this.isConfirmCancelModalOpened.set(false);
  }

  public toggleLogoDropdown() {
    this.isLogoDropdownVisible.set(!this.isLogoDropdownVisible());
  }

  public handleLogoClick(logo: string) {
    this.isLogoDropdownVisible.set(false);
    const logoFormControl = this.saveAssistantForm.get('logo');
    logoFormControl?.setValue(logo);
    logoFormControl?.markAsDirty();
  }

  public handleLogoDropdownOutsideClick(clickedOutside: boolean) {
    if (clickedOutside && this.isLogoDropdownVisible()) {
      this.isLogoDropdownVisible.set(false);
    }
  }

  public handleFileInputChange(input: {
    file: FileWithId;
    action: FileUploadAction;
  }): void {
    const filesControl = this.saveAssistantForm.get('files');

    if (!filesControl) {
      return console.warn('No filesControl found.');
    }

    if (input.action === FileUploadAction.DELETE) {
      return;
    }

    this.filesStore.dispatch(
      FileActions.uploadFile({
        transactionId: this.fileUploadTransactionId(),
        id: input.file.id,
        name: input.file.name,
        extension: input.file.type,
        size: input.file.size,
        file: input.file,
      })
    );
  }

  private handleSaveAssistant() {
    const selectedAssistant = this.selectedAssistant();
    const isEditing = !!selectedAssistant;
    const transactionId = this.fileUploadTransactionId();

    const assistantDto: fromGenerated.CreateAssistantDto = {
      name: this.saveAssistantForm.controls.name.value,
      logo: this.saveAssistantForm.controls.logo.value,
      description: this.saveAssistantForm.controls.description.value,
      prompt: this.saveAssistantForm.controls.prompt.value,
      visibility: this.saveAssistantForm.controls.visibility.value,
    };

    if (isEditing) {
      if (!selectedAssistant) return;

      this.handleEditAssistant(
        selectedAssistant,
        assistantDto,
        this.saveAssistantForm.controls.files.value,
        transactionId
      );
    } else {
      this.handleCreateAssistant(
        assistantDto,
        this.saveAssistantForm.controls.files.value,
        transactionId
      );
    }
  }

  private handleCreateAssistant(
    assistantDto: fromGenerated.CreateAssistantDto,
    files: FileUploadState[],
    transactionId: string
  ) {
    const isCreatingWithDocuments = files.length > 0;

    if (isCreatingWithDocuments) {
      assistantDto = {
        ...assistantDto,
        transactionId,
        documentsToAdd: files.map((file) => ({
          documentId: file.id,
          filename: file.name,
        })),
      };
    }

    this.assistantsStore.dispatch(
      AssistantModalActions.saveAssistant({ assistant: assistantDto })
    );
  }

  private handleEditAssistant(
    selectedAssistant: fromGenerated.AssistantView,
    assistantDto: fromGenerated.UpdateAssistantDto,
    files: FileUploadState[],
    transactionId: string
  ) {
    // Get existing documents
    const existingDocuments =
      selectedAssistant.documents?.filter((doc) => !doc.deletedAt) ?? // Filter out soft deleted documents
      [];

    // Find newly added documents
    const documentsToAdd = files
      .filter(
        (file: FileUploadState) =>
          !existingDocuments.some(
            (doc: fromGenerated.DocumentView) => doc.uuid === file.id
          )
      )
      .map((file: FileUploadState) => ({
        documentId: file.id,
        filename: file.name,
      }));

    // Find the IDs of deleted documents
    const documentIdsToDelete = existingDocuments
      .filter(
        (doc: fromGenerated.DocumentView) =>
          !files.some((file) => file.id === doc.uuid)
      )
      .map((doc: fromGenerated.DocumentView) => doc.uuid);

    // If documents have been added
    if (documentsToAdd.length > 0) {
      assistantDto = {
        ...assistantDto,
        transactionId,
        documentsToAdd,
      };
    }

    // If documents have been removed
    if (documentIdsToDelete.length > 0) {
      assistantDto = {
        ...assistantDto,
        transactionId,
        documentIdsToDelete,
      };
    }

    this.assistantsStore.dispatch(
      AssistantModalActions.editAssistant({
        uuid: selectedAssistant.uuid,
        assistant: assistantDto,
      })
    );
  }

  private handleFormError() {
    console.error('Form is invalid');
    for (const controlName in this.saveAssistantForm.controls) {
      const control = this.saveAssistantForm.get(controlName);
      if (control?.errors) {
        console.error(`Control ${controlName} is invalid:`, control.errors);
      }
    }
  }

  private isSaveAssistantFormDirty() {
    return this.selectedAssistant()
      ? Object.entries(this.saveAssistantForm.value)
          .filter(([key]) => key !== 'files')
          .some(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
            ([key, value]) => value !== (this.selectedAssistant() as any)?.[key]
          )
      : this.saveAssistantForm.dirty;
  }
}

import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  input,
  output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromSeriousSystem from '@serious-system';
import * as fromLayout from '../../../layout';
import * as fromAssistantIcon from './assistant-icon.helper';

@Component({
  selector: 'squadbox-assistant-logo-dropdown',
  standalone: true,
  imports: [fromSeriousSystem.UseAssistantCustomIconDirective],
  template: `
    @for (logo of logos(); track logo) {
    <button
      class="
          flex items-center justify-center
          size-12 rounded-full
          text-primary-500/50
          bg-shades-white hover:bg-primary-500/5
          flex-shrink-0
        "
      (click)="onClickLogo(logo)"
    >
      <svg
        class="size-6"
        [sdUseAssistantCustomIcon]="
          fromAssistantIcon.getAssistantCustomIcon(logo)
        "
      ></svg>
    </button>
    }
  `,
  styles: `
  :host {
    @apply absolute top-20 right-4
    grid grid-cols-4 gap-2
    overflow-y-auto max-h-96 w-60
    bg-shades-white rounded-lg p-2 z-10 border border-neutral-200;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* Internet Explorer and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  :host(.is-mobile) {
    @apply top-20;
  }
  `,
})
export class AssistantLogoDropdownComponent {
  public readonly logos = input.required<string[]>();
  public readonly logoClicked = output<string>();
  public readonly clickedOutside = output<boolean>();

  protected readonly fromAssistantIcon = fromAssistantIcon;

  private readonly layoutStore = inject(Store<fromLayout.LayoutState>);
  public readonly isMobile = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsMobile
  );

  @HostBinding('class.is-mobile') get isMobileHost() {
    return this.isMobile();
  }

  constructor(private elementRef: ElementRef) {}

  public onClickLogo(logo: string) {
    this.logoClicked.emit(logo);
  }

  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (
      targetElement &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      !this.elementRef.nativeElement.contains(targetElement)
    ) {
      this.clickedOutside.emit(true);
    }
  }
}

import { createActionGroup, props } from '@ngrx/store';
import { ViewMode } from './layout.reducer';

export const LayoutActions = createActionGroup({
  source: 'Layout',
  events: {
    'Set Is Mobile': props<{
      isMobile: boolean;
    }>(),
    'Set View Mode': props<{
      viewMode: `${ViewMode}`;
    }>(),
  },
});

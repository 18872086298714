import { DecimalPipe, NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  ViewChild,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import { v4 as uuidv4 } from 'uuid';
import * as fromShared from '../../shared';
import * as fromFileUploadHelpers from './file-upload.helpers';

export type FileWithId = File & { id: string };
export type FilesLoaders = Record<string, FileLoadingState>;

interface FileLoadingState {
  id: string;
  isLoading: boolean;
}

export enum FileUploadAction {
  ADD = 'add',
  DELETE = 'delete',
}

export enum FileUploadError {
  FILE_SIZE_EXCEEDED = 'FILE_SIZE_EXCEEDED',
  FILE_TYPE_NOT_SUPPORTED = 'FILE_TYPE_NOT_SUPPORTED',
}

@Component({
  selector: 'squadbox-file-upload',
  imports: [
    DecimalPipe,
    NgClass,
    TranslateModule,
    fromSeriousSystem.UseArrowIconDirective,
    fromSeriousSystem.FileChipComponent,
    fromShared.FilenameWithoutExtensionPipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    },
  ],
  template: `
    <div class="flex flex-col gap-2 group">
      <input
        #fileInput
        class="hidden"
        type="file"
        accept=".pdf"
        multiple
        (input)="onInput($event)"
      />
      <label
        for="input-file"
        class="typo-caption font-semibold leading-4 h-5 self-start px-1 py-0.5"
        [class]="{
          'text-neutral-400': isDisabled(),
          'text-neutral-700': !isDisabled()
        }"
      >
        {{ label() }}
      </label>
      <button
        id="input-file"
        class="
          group
          border hover:border-primary-300 focus:outline-none
          rounded-lg pl-4 pr-3 py-2 h-12
          grid grid-cols-[1fr,auto] items-center
          disabled:border-neutral-200 disabled:hover:border-neutral-200 disabled:pointer-events-none
        "
        [class.disabled]="isDisabled()"
        [ngClass]="{
          'border-error-500 hover:border-error-600': currentError(),
          'border-neutral-200 hover:border-primary-300': !currentError(),
        }"
        [disabled]="isDisabled()"
        (click)="fileInput.click()"
      >
        <span
          class="typo-p3 text-neutral-500 group-disabled:text-neutral-400 justify-self-start"
          >{{ placeholder() }}</span
        >
        <svg
          sdUseArrowIcon="chevron-small-down"
          class="size-6"
          [class]="{
            'text-neutral-400': isDisabled(),
            'text-neutral-700': !isDisabled()
          }"
        ></svg>
      </button>

      <div class="flex-grow">
        <div>
          <div
            class="flex -mt-1"
            [ngClass]="{
              'justify-between': currentError(),
              'justify-end': !currentError(),
            }"
          >
            @if (currentError()) {
            <span class="typo-caption text-error-600">
              {{ getErrorMessage(currentError()) | translate }}
            </span>
            } @if (!this.isDisabled()) {
            <p class="typo-caption text-neutral-700">
              {{
                fromFileUploadHelpers.calculateTotalFileSizeMB(files())
                  | number : '1.0-0'
              }}/{{ fromFileUploadHelpers.MAX_TOTAL_FILE_SIZE_MB }}MB
            </p>
            }
          </div>
          <div
            class="flex flex-wrap gap-2 mr-14"
            [class.-mt-5]="!currentError()"
          >
            @for (file of files(); track file.id) {
            <sd-file-chip
              [title]="file.name | filenameWithoutExtension"
              [fileType]="fromFileUploadHelpers.getFileType(file.type)"
              [isLoading]="getFileLoadingState(file.id)"
              [isDisabled]="isDisabled()"
              rightIcon="close"
              (rightIconClicked)="onDelete(file)"
            ></sd-file-chip>
            }
          </div>
        </div>
      </div>
    </div>
  `,
})
export class FileUploadComponent implements ControlValueAccessor {
  public readonly fromFileUploadHelpers = fromFileUploadHelpers;
  public readonly translate = inject(TranslateService);
  public readonly label = input<string>(
    this.translate.instant('ASSISTANTS.SAVE_MODAL.FORM.FILES.LABEL') as string
  );
  public readonly placeholder = input<string>(
    this.translate.instant(
      'ASSISTANTS.SAVE_MODAL.FORM.FILES.PLACEHOLDER'
    ) as string
  );
  public readonly filesLoaders = input<FilesLoaders>();
  public readonly valueChanged = output<{
    file: FileWithId;
    action: FileUploadAction;
  }>();
  public readonly files = signal<FileWithId[]>([]);
  public readonly isDisabled = signal(false);
  public readonly currentError = signal<FileUploadError | null>(null);
  public readonly limit = input<number>(0);

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: (value: File[]) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: () => void = () => {};

  /**
   * This one it called when the form control value is patched.
   */
  writeValue(files: FileWithId[]): void {
    this.files.set(files);
    this.currentError.set(null);
    this.resetFileInput();
  }

  registerOnChange(fn: (value: File[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  setErrors(errors: Record<string, unknown> | null): void {
    this.currentError.set(errors ? FileUploadError.FILE_SIZE_EXCEEDED : null);
  }

  updateFiles(files: FileWithId[], action: FileUploadAction): void {
    this.files.set(files);
    this.onChange(this.files());
    this.onTouched();
    this.valueChanged.emit({ file: files[0], action });
  }

  /**
   * Gets called when a new files is picked.
   */
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (!input.files) {
      return;
    }

    this.currentError.set(null);

    // Get new files from input
    const newFiles = Array.from(input.files);

    // Reset input value to allow selecting the same file again
    this.resetFileInput();

    // Check if file size is exceeded based on the size limit (50mb for now)
    // If so, set the error and escape the flow
    if (this.isFileSizeExceed(newFiles)) {
      this.currentError.set(FileUploadError.FILE_SIZE_EXCEEDED);
      return;
    }

    // Get valid files by type (PDF for now)
    const validFilesByType = this.getValidFilesByType(newFiles);

    // Check if files type are not valid
    // If so, set the error and escape the flow
    if (this.isFilesTypeNotValid(newFiles, validFilesByType)) {
      this.currentError.set(FileUploadError.FILE_TYPE_NOT_SUPPORTED);
      return;
    }

    // Add unique id to each file
    const filteredUniqueNewFilesWithId = validFilesByType.map(
      (file) => Object.assign(file, { id: uuidv4() }) satisfies FileWithId
    );

    // Update files
    this.files.update((currentFiles) => [
      ...currentFiles,
      ...filteredUniqueNewFilesWithId,
    ]);
    this.onChange(this.files());
    this.onTouched();

    // Emit value changed for each new file
    filteredUniqueNewFilesWithId.forEach((file) => {
      this.valueChanged.emit({
        file,
        action: FileUploadAction.ADD,
      });
    });
  }

  onDelete(file: FileWithId): void {
    if (this.isDisabled()) {
      return;
    }

    this.resetFileInput();

    this.updateFiles(
      this.files().filter((f: FileWithId) => f.id !== file.id),
      FileUploadAction.DELETE
    );
  }

  public getErrorMessage(error: FileUploadError | null): string {
    switch (error) {
      case FileUploadError.FILE_SIZE_EXCEEDED:
        return this.translate.instant(
          'ASSISTANTS.SAVE_MODAL.FORM.FILES.ERROR_FILE_SIZE',
          { size: fromFileUploadHelpers.MAX_TOTAL_FILE_SIZE_MB }
        ) as string;
      case FileUploadError.FILE_TYPE_NOT_SUPPORTED:
        return this.translate.instant(
          'ASSISTANTS.SAVE_MODAL.FORM.FILES.ERROR_FILE_TYPE',
          {
            types:
              fromFileUploadHelpers.SUPPORTED_FILE_TYPES.join(
                ', '
              ).toUpperCase(),
          }
        ) as string;
      default:
        return '';
    }
  }

  public getFileLoadingState(id: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.filesLoaders()?.[id]?.isLoading ?? false;
  }

  // --------------------------------------------------------------------------------------------
  // Private functions that helps understanding of the component logic
  // --------------------------------------------------------------------------------------------
  private resetFileInput(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  private isFileSizeExceed(newFiles: File[]): boolean {
    const totalSizeWithNewFiles =
      fromFileUploadHelpers.calculateTotalFileSizeMB(this.files()) +
      fromFileUploadHelpers.calculateTotalFileSizeMB(newFiles);

    return totalSizeWithNewFiles > fromFileUploadHelpers.MAX_TOTAL_FILE_SIZE_MB;
  }

  private getValidFilesByType(newFiles: File[]): File[] {
    return newFiles.reduce((files: File[], file: File) => {
      if (!fromFileUploadHelpers.isValidFileType(file)) {
        return files;
      }

      return [...files, file];
    }, []);
  }

  private isFilesTypeNotValid(
    newFiles: File[],
    validFilesByType: File[]
  ): boolean {
    return validFilesByType.length < newFiles.length;
  }
}

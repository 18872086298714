import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { LayoutActions } from './layout.actions';

export const layoutFeatureKey = 'layout';

export enum ViewMode {
  CONVERSATION = 'conversation',
  DOC_VIEWER = 'doc-viewer',
}

export interface LayoutState {
  isMobile: boolean;
  viewMode: `${ViewMode}`;
}

const initialLayoutState: LayoutState = {
  isMobile: false,
  viewMode: 'conversation',
};

export const layoutFeature = createFeature({
  name: layoutFeatureKey,
  reducer: createReducer(
    initialLayoutState,
    on(LayoutActions.setIsMobile, (state, { isMobile }) => ({
      ...state,
      isMobile,
    })),
    on(
      LayoutActions.setViewMode,
      (state, { viewMode }: { viewMode: `${ViewMode}` }) => ({
        ...state,
        viewMode,
      })
    )
  ),
  extraSelectors: ({ selectViewMode }) => ({
    selectIsConversationMode: createSelector(
      selectViewMode,
      (viewMode) => viewMode === 'conversation'
    ),
    selectIsDocViewerMode: createSelector(
      selectViewMode,
      (viewMode) => viewMode === 'doc-viewer'
    ),
  }),
});

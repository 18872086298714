export const hasAnyTrueValue = (obj: object): boolean => {
  // Get all values from the object
  const values = Object.values(obj);

  // Check each value
  return values.some((value) => {
    // Case 1: If it's a boolean, return its value
    if (typeof value === 'boolean') {
      return value;
    }

    // Case 2: If it's a nested object, recurse into it
    if (isNestedObject(value)) {
      return hasAnyTrueValue(value);
    }

    // Case 3: Any other type, return false
    return false;
  });
};

// Type guard for nested objects
function isNestedObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null;
}
